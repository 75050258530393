import cls from "classnames";
import { QOOHOO_WEBSITE_DOMAIN } from "../constants/env";
import { getInitialsOfName } from "../utils/string";

function UserAvatar({
  avatarUrl,
  userName,
  isUserNameSetByUser = true,
  className,
  style={},
  alt = "",
}) {
  let userAvatarContent = null;

  if (!avatarUrl && !userName) {
    return null;
  }

  if (!isUserNameSetByUser) {
    return (
      <img
        className="w-5 h-5"
        style={style}
        src={`${QOOHOO_WEBSITE_DOMAIN}/assets/icons/profile.svg`}
        alt="profile pic"
      />
    );
  }

  if (avatarUrl) {
    userAvatarContent = (
      <img
        src={avatarUrl}
        alt={alt}
        className={cls("object-cover object-center", className)}
        style={style}
      />
    );
  } else {
    userAvatarContent = getInitialsOfName(userName);
  }

  return userAvatarContent;
}

export default UserAvatar;
