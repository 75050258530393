import cls from "classnames";
import Link from "next/link";

import UserAvatar from "@qoohoo/common/components/UserAvatar";
import Spinner from "@qoohoo/common/library/loader/Spinner";
import NavLayout from "@qoohoo/common/components/NavBar/NavLayout";
import useHasHydrated from "@qoohoo/common/hooks/useHasHydrated";
import { mergeDeep } from "@qoohoo/common/utils/object";

import useSessionStore from "store/sessionStore";
import useStore from "store/store";
import { LoginButton } from "components/auth/AuthButtons";
import UserAuthDropdown from "components/Dropdown/Auth/UserAuthDropdown";


export function AuthComponent({ theme, className, ...otherProps }) {
  const userData = useSessionStore((state) => state.userData);
  const isLoading = useStore((state) => state.loading);
  const isHydrated = useHasHydrated();
  const isLoggedIn = !!userData?.uid;

  const { authDropdownTheme, loginButtonTheme } = theme;

  let content;

  if (isLoading || !isHydrated) {
    content = <Spinner className={className} size="xs" />;
  } else if (isLoggedIn) {
    content = (
      <UserAuthDropdown
        theme={authDropdownTheme}
        className={className}
        {...otherProps}
      />
    );
  } else {
    content = (
      <LoginButton
        className={cls(
          "px-8 h-10 rounded-full overflow-hidden flex items-center justify-center cursor-pointer hover:brightness-90",
          loginButtonTheme.buttonClass,
          className,
        )}
        style={{
          color: loginButtonTheme.primaryColor,
          border: `1px solid ${loginButtonTheme.borderColor}`,
          background: loginButtonTheme.background
        }}
        {...otherProps}
      >
        Login
      </LoginButton>
    );
  }

  return content;
}

AuthComponent.theme = {
  light: {
    authDropdownTheme: UserAuthDropdown.theme.light,
    loginButtonTheme: {
      primaryColor: "#16191B",
      background: "rgba(0,0,0,0.07)",
      borderColor: "transparent",
      buttonClass: "",
    },
  },
  dark: {
    authDropdownTheme: UserAuthDropdown.theme.dark,
    loginButtonTheme: {
      primaryColor: "#ffffff",
      background: "rgba(255,255,255,0.07)",
      borderColor: "transparent",
      buttonClass: "",
    },
  }
}

export default function CreatorNavbar({
  currentScreen,
  creator: _creator,
  isSticky = false,
  showAuth = true,
  isDark = true,
  theme = null,
}) {
  const defaultTheme = isDark ? CreatorNavbar.theme.dark : CreatorNavbar.theme.light;
  const navTheme = mergeDeep(defaultTheme, theme);

  const authTheme = navTheme.auth;

  const creator = {
    avatarUrl:
      _creator?.user_details?.avatar?.url ||
      _creator?.avatar?.url ||
      _creator?.avatar_url ||
      _creator?.avatar,
    fullname: _creator?.user_details?.fullname || _creator?.fullname,
    username: _creator?.user_details?.username || _creator?.username,
  };

  function renderNameAndAvatar() {
    return (
      <Link href={`/${creator.username}`} className="flex items-center">
        <div className="w-9 h-9 flex items-center justify-center rounded-full overflow-hidden mr-3 text-center text-white bg-[#2b3135]">
          <UserAvatar
            avatarUrl={creator.avatarUrl}
            userName={creator.fullname}
            alt={creator.fullname}
          />
        </div>
        <div className={cls("font-inter text-base md:text-lg")}>
          {creator.fullname}
        </div>
      </Link>
    );
  }

  return (
    <NavLayout
      currentScreen={currentScreen}
      navItems={null}
      isSticky={isSticky}
      theme={navTheme}
      logoComponent={renderNameAndAvatar}
      authNavComponent={(props) =>
        showAuth ? <AuthComponent theme={authTheme}  {...props} /> : null
      }
      hamMenuComponent={null}
    />
  );
}


CreatorNavbar.theme = {
  light: {
    primaryColor: "#16191B",
    auth: AuthComponent.theme.light,
  },
  dark: {
    primaryColor: "#ffffff",
    auth: AuthComponent.theme.dark,
  }
}