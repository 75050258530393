import { useState, Fragment } from "react";
import cls from "classnames";
import { Transition } from "@headlessui/react";

import { isValidUsername } from "@qoohoo/common/utils/string";
import { ChevronDownIcon } from "@qoohoo/common/library/Icons";
import Spinner from "@qoohoo/common/library/loader/Spinner";
import UserAvatar from "@qoohoo/common/components/UserAvatar";

import useSessionStore from "store/sessionStore";
import useStore from "store/store";
import { LogoutButton } from "components/auth/AuthButtons";

function UserAuthDropdown({
  theme = UserAuthDropdown.theme.dark,
  className,
  style,
  currentScreen,
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const userData = useSessionStore((state) => state.userData);
  const isLoading = useStore((state) => state.loading);

  const isLoggedIn = !!userData?.uid;

  const userFullName = userData?.name || "My account";
  const userFirstName = userData?.name?.split(" ")?.[0] || "My Account";
  const userName = isValidUsername(userData?.username)
    ? userData?.username
    : "qoohoo_buddy";
  const phoneNumber = userData?.mobile;
  const countryCode = userData?.countryCode;

  function handleButtonClick() {
    setDropdownOpen((prev) => !prev);
  }

  function handleBlur(event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setDropdownOpen(false);
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Escape") {
      setDropdownOpen(false);
    }
  }

  const dropdownContent = (
    <div
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      tabIndex="0"
      className="z-10 sm:relative text-left outline-none focus:outline-none"
    >
      <button
        onClick={handleButtonClick}
        className="h-7 md:h-10 md:px-3 flex justify-center items-center gap-1 appearance-none rounded-full outline-none focus:outline-none"
        style={{ background: theme.buttonBackground, color: theme.buttonPrimaryColor }}
      >
        <div className="flex md:hidden items-center justify-center bg-[#1F2429] font-bold w-7 h-7 md:w-9 md:h-9 overflow-hidden rounded-full">
          <UserAvatar
            avatarUrl={userData.avatar}
            userName={userFullName}
            isUserNameSetByUser={!!userData?.name}
            alt=""
          />
        </div>
        <div
          className={cls("hidden md:block max-w-[8rem] overflow-ellipsis")}
          style={{ color: theme.buttonPrimaryColor }}
        >
          {userFirstName}
        </div>
        <ChevronDownIcon
          className={cls(
            "hidden md:block transition-transform stroke-current",
            dropdownOpen && "rotate-180"
          )}
          style={{ color: theme.buttonPrimaryColor }}
        />
      </button>
      <Transition
        show={dropdownOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-out duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={cls(
            "absolute w-full sm:w-72 z-50",
            "rounded-lg overflow-hidden",
            "top-12 sm:top-auto right-0 sm:mt-2 origin-top-right",
            "outline-none focus:outline-none children:outline-none focus:children:outline-none"
          )}
          style={{
            background: theme.background,
            boxShadow: theme.shadow,
            border: `1px solid ${theme.borderColor}`,
          }}
        >
          <div className="w-full p-4">
            <div className="flex gap-2">
              <div
                className="flex items-center justify-center font-bold w-11 h-11 overflow-hidden rounded-full"
                style={{ color: theme.primaryColor }}
              >
                <UserAvatar
                  avatarUrl={userData.avatar}
                  userName={userFullName}
                  isUserNameSetByUser={!!userData.name}
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="flex flex-col">
                <div>
                  <h1
                    className="font-bold text-base text-ellipsis"
                    style={{ color: theme.primaryColor }}
                  >
                    {userFullName}
                  </h1>
                  <h2
                    className="mt-1 text-sm font-semibold text-ellipsis"
                    style={{ color: theme.primaryColor }}
                  >
                    @{userName}
                  </h2>
                </div>
                <div
                  className="mt-4 text-sm"
                  style={{ color: theme.secondaryColor }}
                >
                  +{countryCode}
                  {phoneNumber}
                </div>
              </div>
            </div>
          </div>
          <div
            className="py-3 flex justify-center items-center border-t"
            style={{ borderColor: theme.separatorColor }}
          >
            <LogoutButton
              currentScreen={currentScreen}
              className={cls("px-16 h-10 rounded-full overflow-hidden flex items-center justify-center cursor-pointer hover:brightness-90", theme.buttonClass)}
              style={{
                background: theme.buttonBackground,
                color: theme.primaryColor,
              }}
            >
              Logout
            </LogoutButton>
          </div>
        </div>
      </Transition>
    </div>
  );

  function renderContent() {
    let content = null;

    if (isLoading) {
      content = <Spinner size="xs" />;
    } else if (isLoggedIn) {
      content = dropdownContent;
    }

    return content;
  }

  return (
    <div className={className} style={style}>
      {renderContent()}
    </div>
  );
}

UserAuthDropdown.theme = {
  light: {
    primaryColor: "#16191B",
    buttonPrimaryColor: "#16191B",
    secondaryColor: "#606268",
    background: "#ffffff",
    buttonBackground: "rgba(0,0,0,0.1)",
    separatorColor: "rgba(0,0,0,0.1)",
    borderColor: "rgba(0,0,0,0.1)",
    shadow: "0px 2px 10px 0px #0000000D",
    buttonClass: "",
  },
  dark: {
    primaryColor: "#ffffff",
    buttonPrimaryColor: "#ffffff",
    secondaryColor: "#85878D",
    background: "#16191B",
    buttonBackground: "rgba(255,255,255,0.1)",
    separatorColor: "rgba(255,255,255,0.1)",
    borderColor: "rgba(255,255,255,0.1)",
    shadow: "none", // figma - 0px 2px 10px 0px #FFFFFF0D looks bad, revisit
    buttonClass: "",
  },
};

export default UserAuthDropdown;
